import React from "react";
import { useRoutes } from "react-router-dom";
import SingleBook from "../Books/SingleBook";
import HomeScreen from "../HomeScreen";
import UploadPage from "../UploadPage/UploadPage";
const AllRoutes = () => {
	let element = useRoutes([
		{
			path: "/",
			element: <HomeScreen />,
		},

		{
			path: "/view/:id/details",
			element: <SingleBook />,
		},
		{
			path: "/upload",
			element: <UploadPage />,
		},
	]);

	return element;
};

export default AllRoutes;
